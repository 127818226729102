<div class="diversionCentre-clients">
	<form novalidate class="was-validated" (ngSubmit)="saveDiversionCentre(diversionForm)" [formGroup]="diversionForm">
		<div>
			<div class="accordion accordion-theme" id="accordionExample">
				<div class="accordion-item">
					<h2 class="accordion-header" id="headingOne" *ngIf="!isBulkCheckin">
						<button class="accordion-button" type="button" data-bs-toggle="collapse"
							data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
							<b>Client Details</b>
						</button>
					</h2>
					<div id="collapseOne" *ngIf="!isBulkCheckin" class="accordion-collapse collapse show"
						aria-labelledby="headingOne">
						<div class="accordion-body">
							<div class="table-responsive mb-4">
								<table class="table table-theme table-stripedTheme  text-center">
									<thead>
										<tr>
											<th>First Name</th>
											<th>Last Name</th>
											<th>Mobile</th>
											<th>DOB</th>
											<th>Location</th>
											<th>Time in</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>{{(currentClient &&
												currentClient.firstName)?currentClient.firstName:''}}</td>
											<td>{{(currentClient && currentClient.lastName)?currentClient.lastName:''}}
											</td>
											<td>{{(currentClient && currentClient.phone)?currentClient.phone:''}}</td>
											<td>{{(currentClient && currentClient.dob)?(currentClient.dob | date) :''}}
											</td>
											<td>
												<select #locationDd class="form-select"
													aria-label="Default select example" formControlName="locationId"
													[ngClass]="{ 'is-invalid': diversionForm.controls.locationId.errors }"
													maxlength="250">
													<option value="" disabled selected>Select Location</option>
													<option *ngFor="let loc of locationList" value="{{loc.id}}">
														{{loc.name}}</option>
												</select>
												<div *ngIf="diversionForm.controls.locationId.errors"
													class="invalid-feedback">
													<div *ngIf="diversionForm.controls.locationId.errors.required">
														Location is required
													</div>
												</div>
											</td>
											<td>{{diversionForm.controls['timeIn'].value | date :'HH:mm':tZone }}</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="bg-gray form-theme box-full py-xl-4 py-md-3 py-2">
								<div class="row  g-3 g-xl-4 align-items-center">
									<div class="col-md-6"><label>Address:</label></div>
									<div class="col-md-6"><label>{{(currentClient &&
											currentClient.address)?currentClient.address:'N/A'}}</label></div>
								</div>
							</div>

						</div>
					</div>
				</div>
				<div class="accordion-item">
					<h2 *ngIf="!isBulkCheckin" class="accordion-header" id="headingTwo">
						<button class="accordion-button" type="button" data-bs-toggle="collapse"
							data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
							<b>Diversion Details</b>
						</button>
					</h2>
					<div id="collapseTwo" class="accordion-collapse collapse show" aria-labelledby="headingTwo">
						<div class="accordion-body">
							<section>
								<div *ngIf="!isBulkCheckin">
									<div class="form-theme py-xl-4 py-md-3 py-2">
										<div class="row g-3 g-xl-4 ">
											<div class="col-md-4">
												<label for="accessComment">How did the person access the
													service?</label>
											</div>
											<div class="col-md-8 textarea-wrapper">
												<select #accessCommentDd class="form-select" id="accessComment"
													formControlName="accessComment"
													[ngClass]="{ 'is-invalid': diversionForm.controls.accessComment.errors }"
													maxlength="250">
													<option *ngFor="let acl of accessCommentList" value="{{acl.value}}">
														{{acl.label}}</option>
												</select>

												<div *ngIf="diversionForm.controls.accessComment.errors"
													class="invalid-feedback">
													<div *ngIf="diversionForm.controls.accessComment.errors.required">
														Accesscomment required
													</div>
												</div>
												<span class="remaning">{{accessCommentDd.value.length}}/250</span>
											</div>
										</div>
									</div>
									<div class="form-theme py-xl-4 py-md-3 py-2">
										<div class="row g-3 g-xl-4">
											<div class="col-xl-4">
												<label>
													Has the client been involved in any altercations immediately prior
													to their arrival at the
													Diversion Centre?
												</label>
											</div>
											<div class="col-md-2">
												<div class="form-check mb-0">
													<input type="radio" value="false" id="isAlter_no"
														class="form-check-input" formControlName="isAlter"
														(click)="changeIsAlter($event)">
													<label for="isAlter_no" class="form-check-label"> No </label>
												</div>
											</div>
											<div class="col-xl-6 col-md-10">
												<div class="form-check mb-2">
													<input type="radio" value="true" id="isAlter_yes"
														class="form-check-input" formControlName="isAlter"
														(click)="changeIsAlter($event)">
													<label for="isAlter_yes" class="form-check-label"> Yes</label>
												</div>
												<textarea
													[ngClass]="{'is-invalid':diversionForm.controls['alterComment'].errors}"
													class="form-control" rows="2" formControlName="alterComment"
													[required]="altercommentRequired" id="alterComment"></textarea>
												<div class="invalid-feedback" *ngIf="altercommentRequired">
													Alter comment is required
												</div>
											</div>
										</div>
									</div>
									<div class="bg-gray form-theme box-full py-xl-4 py-md-3 py-2">
										<div class="row  g-3 g-xl-4">
											<div class="col-xl-4"><label>Does the client have any belongings on them
													(Including
													medication)?</label></div>
											<div class="col-md-2">
												<div class="form-check mb-0">
													<input type="radio" value="false" id="isBelonging_no"
														class="form-check-input" formControlName="isBelonging"
														(click)="changeIsBelonging($event)">
													<label for="isBelonging_no" class="form-check-label"> No </label>
												</div>
											</div>
											<div class="col-xl-6 col-md-10">
												<div class="form-check mb-2">
													<input type="radio" value="true" id="isBelonging_yes"
														class="form-check-input" formControlName="isBelonging"
														(click)="changeIsBelonging($event)">
													<label for="isBelonging_yes" class="form-check-label"> Yes </label>
												</div>
												<textarea
													[ngClass]="{'is-invalid':diversionForm.controls['belongingComment'].errors}"
													class="form-control" rows="2" formControlName="belongingComment"
													[required]="belongingCommentRequired"
													id="belongingComment"></textarea>
												<div class="invalid-feedback" *ngIf="belongingCommentRequired">
													belonging comment is required</div>
											</div>
										</div>
									</div>
									<div class="form-theme py-xl-4 py-md-3 py-2">
										<div class="row g-3 g-xl-4">
											<div class="col-xl-4">
												<label>
													Are there any "No contact" Domestic Violence Orders in place?
												</label>
											</div>
											<div class="col-md-2">
												<div class="form-check mb-0">
													<input type="radio" value="false" id="isViolence_no"
														class="form-check-input" formControlName="isViolence"
														(click)="changeIsViolence($event)">
													<label for="isViolence_no" class="form-check-label"> No </label>
												</div>
											</div>
											<div class="col-xl-1 col-md-2">
												<div class="form-check mb-0">
													<input type="radio" value="true" id="isViolence_yes"
														class="form-check-input" formControlName="isViolence"
														(click)="changeIsViolence($event)">
													<label for="isViolence_yes" class="form-check-label"> Yes </label>
												</div>
											</div>
											<div class="col-xl-5 col-md-8">
												<table class="table-addMore w-100" formArrayName="domesiticViolances">

													<tr *ngFor="let quantity of domesiticViolances().controls; let i=index"
														[formGroupName]="i">
														<td>
															<input type="text" formControlName="violancerName"
																id="violancerName" class="form-control">
														</td>
														<td>
															<div (click)="removeDomesiticViolance(i)">
																<svg class="close-svg float-right" focusable="false"
																	viewBox="0 0 24 24" aria-hidden="true">
																	<path
																		d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z">
																	</path>
																</svg>
															</div>
														</td>
													</tr>
													<tr>
														<th colspan="2">
															<button type="button" (click)="addDomesiticViolance()"
																class="btn btn-success mnw-auto">
																Add More
															</button>
														</th>
													</tr>
												</table>
											</div>
											<div class="col-xl-5 col-md-8">
												<div #Domestic></div>
												<!-- <div class="form-check mb-2">
                        <div class="row g-3 align-items-center">
                          <div class="col-xxl-6 col-xl-5 col-lg-7 col-md-6">
                            <input type="text" class="form-control" formControlName="violancerName"
                              placeholder="Contact Name">
                          </div>
                          <div class="col-xxl-6 col-xl-7 col-lg-5 col-md-6">
                            <a (click)="addDomestic()" class="btn btn-success mnw-auto">ADD MORE</a>
                            <a href="#" style="opacity: 0;"> <svg class="close-svg ms-2" focusable="false"
                                viewBox="0 0 24 24" aria-hidden="true">
                                <path
                                  d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z">
                                </path>
                              </svg></a>
                          </div>
                        </div>
                      </div> -->
											</div>
										</div>
									</div>
									<div class="bg-gray form-theme box-full py-xl-4 py-md-3 py-2">
										<div class="row  g-3 g-xl-4">
											<div class="col-xl-4"><label>Does the client have any known medical
													conditions?</label></div>
											<div class="col-md-2">
												<div class="form-check mb-0">
													<input type="radio" value="false" id="isMedical_no"
														class="form-check-input" formControlName="isMedical">
													<label for="isMedical_no" class="form-check-label"> No </label>
												</div>
											</div>
											<div class="col-xl-6 col-md-10">
												<div class="form-check mb-2">
													<input type="radio" value="true" id="isMedical_yes"
														class="form-check-input" formControlName="isMedical">
													<label for="isMedical_yes" class="form-check-label">
														Yes. If yes, you must complete the Client needs and risk
														identification form
													</label>
												</div>
											</div>
										</div>
										<div class="medical-block border border-secondary p-3 my-3"
											*ngIf="diversionForm.controls['isMedical'].value === 'true'">
											<div class="py-xl-4 py-md-3 py-2">
												<div class="row  g-3 g-xl-4">
													<div class="col-xl-4"><label>Does an ambulance need to be
															contacted?</label></div>
													<div class="col-md-2">
														<div class="form-check mb-0">
															<input type="radio" value="false" id="isAmbulance_no"
																class="form-check-input" formControlName="isAmbulance">
															<label for="isAmbulance_no" class="form-check-label"> No
															</label>
														</div>
													</div>
													<div class="col-xl-6 col-md-10">
														<div class="form-check mb-2">
															<input type="radio" value="true" id="isAmbulance_yes"
																class="form-check-input" formControlName="isAmbulance">
															<label for="isAmbulance_yes" class="form-check-label"> Yes
															</label>
														</div>
													</div>
												</div>
											</div>
											<div class="py-xl-4 py-md-3 py-2">
												<div class="row  g-3 g-xl-4">
													<div class="col-xl-4"><label>Is the client on any
															medication?</label></div>
													<div class="col-md-2">
														<div class="form-check mb-0">
															<input type="radio" value="false" id="isOnMedication_no"
																class="form-check-input"
																formControlName="isOnMedication"
																(click)="changeIsOnMedication($event)">
															<label for="isOnMedication_no" class="form-check-label"> No
															</label>
														</div>
													</div>
													<div class="col-xl-6 col-md-10">
														<div class="form-check mb-2">
															<input type="radio" value="true" id="isOnMedication_yes"
																class="form-check-input"
																formControlName="isOnMedication"
																(click)="changeIsOnMedication($event)">
															<label for="isOnMedication_yes" class="form-check-label">
																Yes. If yes name the medication
															</label>
														</div>
														<textarea
															[ngClass]="{'is-invalid':diversionForm.controls['medicationName'].errors}"
															class="form-control" rows="2"
															formControlName="medicationName"
															[required]="medicationNameRequired"></textarea>
														<div class="invalid-feedback" *ngIf="medicationNameRequired">
															medication name is required
														</div>
													</div>
												</div>
											</div>
											<div class="py-xl-4 py-md-3 py-2">
												<div class="row  g-3 g-xl-4">
													<div class="col-xl-4"><label>Do arrangements need to be made for
															medication to be
															provided to
															the
															client?</label></div>
													<div class="col-md-2">
														<div class="form-check mb-0">
															<input type="radio" value="0" id="isArrangements_no"
																class="form-check-input"
																formControlName="isArrangements">
															<label for="isArrangements_no" class="form-check-label"> No
															</label>
														</div>
													</div>
													<div class="col-md-2">
														<div class="form-check mb-2">
															<input type="radio" value="1" id="isArrangements_unknown"
																class="form-check-input"
																formControlName="isArrangements">
															<label for="isArrangements_unknown"
																class="form-check-label"> Unknown </label>
														</div>
													</div>
													<div class="col-xl-4 col-md-8">
														<div class="form-check mb-2">
															<input type="radio" value="2" id="isArrangements_yes"
																class="form-check-input"
																formControlName="isArrangements">
															<label for="isArrangements_yes" class="form-check-label">
																Yes. If yes, arrange for client to be taken to a doctor.
															</label>
														</div>
													</div>
												</div>
											</div>
											<div class="py-xl-4 py-md-3 py-2">
												<div class="row  g-3 g-xl-4">
													<div class="col-xl-4"><label>If applicable when did the client last
															take their
															medication?</label></div>
													<div class="col-xl-4 col-md-6 ">
														<input type="date" value="1" id="medicationDate"
															class="form-control" formControlName="medicationDate">
													</div>
													<div class="col-xl-4 col-md-6 ">
														<input type="time" value="2" id="medicationTime"
															class="form-control" formControlName="medicationTime">
													</div>
												</div>
											</div>
											<div class="pt-4">
												<h4>GENERAL OBSERVATIONS</h4>
											</div>
											<div class="py-xl-4 py-md-3 py-2">
												<div class="row  g-3 g-xl-4">
													<div class="col-xl-4"><label>Is the client currently showing any
															signs of aggression e.g.
															spitting, swearing, hitting, punching?</label></div>
													<div class="col-md-2">
														<div class="form-check mb-0">
															<input type="radio" value="false" id="isAggression_no"
																class="form-check-input" formControlName="isAggression">
															<label for="isAggression_no" class="form-check-label">
																No
															</label>
														</div>
													</div>
													<div class="col-xl-6 col-md-10">
														<div class="form-check mb-2">
															<input type="radio" value="true" id="isAggression_yes"
																class="form-check-input" formControlName="isAggression">
															<label for="isAggression_yes" class="form-check-label">
																Yes. If yes, don not approach the client - First assess
																whether a medical and/or police
																response is required.
															</label>
														</div>
													</div>
												</div>
											</div>
											<div class="py-xl-4 py-md-3 py-2">
												<div class="row  g-3 g-xl-4">
													<div class="col-lg-3">
														<label for="symptoms_1">Does the client have any of the
															following symptoms?</label>
													</div>
													<table class="col-lg-9" formArrayName="medicalObservations">
														<tr *ngFor="let quantity of medicalObservations().controls; let i=index"
															[formGroupName]="i">
															<td colspan="2">
																<div class="col-md p-2">
																	<select class="form-select" id="symptoms_{{i}}"
																		formControlName="symptoms"
																		(change)="symptomsSelected($event)">
																		<ng-container *ngFor="let prm of symptomsList">
																			<option value="{{prm.value}}">
																				{{prm.label}}
																			</option>
																		</ng-container>
																	</select>
																</div>
															</td>
															<td>
																<div (click)="removeMedicalObservations(i)">
																	<svg class="close-svg ms-2 float-right"
																		focusable="false" viewBox="0 0 24 24"
																		aria-hidden="true">
																		<path
																			d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z">
																		</path>
																	</svg>
																</div>
															</td>
														</tr>
														<tr>
															<th>
																<button type="button" (click)="addMedicalObservations()"
																	class="btn btn-success mnw-auto">Add
																	More
																</button>
															</th>
														</tr>
													</table>
													<!-- <div class="col-md">
                          <select class="form-select" id="accessComment" formControlName="accessComment">
                            <option *ngFor="let sym of symptomsList" value="sym.value">{{sym.label}}</option>
                          </select>
                        </div>
                        <div class="col-md-auto">
                          <a href="#" class="btn btn-success mnw-auto">ADD MORE</a>
                          <a href="#" style="opacity: 0;"><svg focusable="false" viewBox="0 0 24 24" aria-hidden="true"
                              class="close-svg ms-2">
                              <path
                                d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z">
                              </path>
                            </svg></a>
                        </div> -->
												</div>
											</div>
										</div>
										<div class="pt-4">
											<h4>OBSERVATIONS OF CLIENT WELLBEING</h4>
										</div>
									</div>
									<div class="form-theme py-xl-4 py-md-3 py-2">
										<div class="row g-3 g-xl-4 ">
											<div class="col-xl-4">
												<label>If you notice the client is acting strangely or particularly out
													of character it is
													important that you talk to them about it. Select any of the
													following behaviours shown by the
													client.
												</label>
											</div>
											<div class="col-xl-8">
												<table class="table-addMore w-100"
													formArrayName="wellBeingObservations">
													<tr *ngFor="let quantity of wellBeingObservations().controls; let i=index"
														[formGroupName]="i">
														<td>
															<select class="form-select" id="behaviour_{{i}}"
																formControlName="behaviour">
																<ng-container *ngFor="let prm of behaviourList">
																	<option value="{{prm.value}}">
																		{{prm.label}}
																	</option>
																</ng-container>
															</select>
														</td>
														<td>
															<div (click)="removeWellBeingObservations(i)">
																<svg class="close-svg ms-2 float-right"
																	focusable="false" viewBox="0 0 24 24"
																	aria-hidden="true">
																	<path
																		d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z">
																	</path>
																</svg>
															</div>
														</td>
													</tr>
													<tr>
														<th colspan="2">
															<button type="button" (click)="addWellBeingObservations()"
																class="btn btn-success mnw-auto">Add
																More
															</button>
														</th>
													</tr>
												</table>
												<!-- <div #Notice></div>
                      <div class="row g-3 g-xl-4 align-items-center justify-content-between">
                        <div class="col-md-6">
                          <select aria-label="Default select example" class="form-select">
                            <option>Male</option>
                            <option>Female</option>
                          </select>
                        </div>
                        <div class="col-md-auto">
                          <a (click)="addNotice()" class="btn btn-red">ADD MORE</a>
                          <a href="#" style="opacity: 0;"> <svg class="close-svg ms-2" focusable="false"
                              viewBox="0 0 24 24" aria-hidden="true">
                              <path
                                d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z">
                              </path>
                            </svg></a>
                        </div>
                      </div> -->
											</div>
										</div>
									</div>
									<div class="form-theme pt-xl-4 pt-md-3 pt-2">
										<h4>CLIENT OBSERVATION RECORD</h4>
										<div class="observation-box">
											<div class="table-responsive">

												<table class="table table-theme table-stripedTheme table-dashboard"
													v-align="top" formArrayName="clientObservationForms">
													<thead>
														<tr>
															<th>Did you check on them?</th>
															<th>Were they awake or asleep?</th>
															<th>Status Change?</th>
															<th>Comments <br> <small>(Mandatory when status
																	changed)</small></th>
															<th></th>
														</tr>
													</thead>
													<tbody>

														<tr *ngFor="let quantity of clientObservationForms().controls; let i=index"
															[formGroupName]="i">
															<td>
																<input readonly type="text"
																	class="border-0 form-control"
																	value="{{quantity.controls['clientCheck'].value |  date :'d/M/yy h:mm':tZone}}"
																	id="clientCheck_{{i}}">
															</td>
															<td>
																<select aria-label="Default select example"
																	class="form-select" formControlName="clientAwake"
																	id="clientAwake_{{i}}">
																	<option value="1">Awake</option>
																	<option value="2">Sleep</option>
																</select>
															</td>
															<td>
																<select aria-label="Default select example"
																	class="form-select"
																	formControlName="clientStatusChange"
																	id="clientStatusChange_{{i}}">
																	<option value="1">Yes</option>
																	<option value="2">No</option>
																</select>
															</td>
															<td>
																<input type="text" class="form-control"
																	placeholder="Status Comments Here..."
																	formControlName="statusComments"
																	id="statusComments_{{i}}">
															</td>
															<td>
																<div (click)="removeclientObservationForms(i)">
																	<svg class="close-svg ms-2 float-right"
																		focusable="false" viewBox="0 0 24 24"
																		aria-hidden="true">
																		<path
																			d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z">
																		</path>
																	</svg>
																</div>
															</td>
														</tr>
														<tr>
															<th colspan="5">
																<button type="button"
																	(click)="addclientObservationForms()"
																	class="btn btn-success mnw-auto">
																	Add More
																</button>
															</th>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
									<div class="form-theme py-xl-4 py-md-3 py-2">
										<h4>CLIENT BACKGROUND INFORMATION</h4>
										<div #BackgroundInfo></div>
										<div class="row g-3 g-xl-4 align-items-center">

											<table class="table-addMore w-100"
												formArrayName="clientBackgroundInformations">

												<tr *ngFor="let quantity of clientBackgroundInformations().controls; let i=index"
													[formGroupName]="i">
													<td>
														<textarea class="form-control" rows="2" id="information_{{i}}"
															formControlName="information"></textarea>
													</td>
													<td>
														<div class="col-md-auto ms-auto"
															(click)="removeClientBackgroundInformations(i)">
															<svg class="close-svg ms-2 float-right" focusable="false"
																viewBox="0 0 24 24" aria-hidden="true">
																<path
																	d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z">
																</path>
															</svg>
														</div>
													</td>
												</tr>
												<tr>
													<th colspan="2">
														<button type="button"
															(click)="addClientBackgroundInformations()"
															class="btn btn-success mnw-auto">Add
															More
														</button>
													</th>
												</tr>
											</table>
											<!-- <div class="col-lg-8 col-md-7">
                      <textarea class="form-control" rows="2"></textarea>
                    </div>
                    <div class="col-md-auto ms-auto">
                      <a (click)="addBackgroundInfo()" class="btn btn-red">ADD MORE</a>
                      <a href="#" style="opacity: 0;"> <svg class="close-svg ms-2" focusable="false" viewBox="0 0 24 24"
                          aria-hidden="true">
                          <path
                            d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z">
                          </path>
                        </svg></a>
                    </div> -->
										</div>
									</div>
								</div>
								<section *ngIf="isCheckout">
									<div class="form-theme py-xl-4 py-md-3 py-2">
										<h4>CLIENT EXIT DETAILS</h4>
									</div>
									<div class="bg-gray form-theme box-full py-xl-4 py-md-3 py-2">
										<div class="row  g-3 g-xl-4">
											<div class="col-xl-4"><label>did you discuss any supports the client
													needs?</label></div>
											<div class="col-md-2">
												<div class="form-check mb-0">
													<input type="radio" value="false" id="noReferral"
														class="form-check-input" formControlName="isReferral"
														(click)="changeReferral($event)">
													<label for="noReferral" class="form-check-label"> No </label>
												</div>
											</div>
											<div class="col-md-2">
												<div class="form-check mb-0">
													<input type="radio" value="true" id="yesReferral"
														class="form-check-input" formControlName="isReferral"
														(click)="changeReferral($event)">
													<label for="yesReferral" class="form-check-label">
														Yes
													</label>
												</div>
											</div>
											<div class="col-md-4"
												*ngIf="diversionForm.controls['isReferral'].value === 'true'">
												<app-select-input #ref [form]="referalForm" field="clientReferal"
													label="Referrals" [allowClear]="true" [multiple]="true"
													[selectedValue]="referalServices" [errors]='{
															required: "Referal required"
														}'>
													<option selected="selected" value="{{ref.value}}"
														*ngFor="let ref of referalDataList">
														{{ref.label}}</option>
												</app-select-input>
											</div>
										</div>
									</div>
									<div class="form-theme py-xl-4 py-md-3 py-2 d-none">
										<div class="row g-3 g-xl-4 align-items-center">
											<div class="col-md-6">
												<label for="estimatedTime">Estimated time spent (minutes)?</label>
											</div>
											<div class="col-md-4">
												<input type="number" id="estimatedTime" class="form-control"
													formControlName="estimatedTime">
											</div>
										</div>
									</div>
									<div class="form-theme py-xl-4 py-md-3 py-2">
										<div class="row g-3 g-xl-4 align-items-center">
											<div class="col-xl-4">
												<label>Has the client collected their personal belongings?</label>
											</div>
											<div class="col-md-2">
												<div class="form-check mb-0">
													<input type="radio" value="1" id="isBelongingTaken_no"
														class="form-check-input" formControlName="isBelongingTaken">
													<label for="isBelongingTaken_no" class="form-check-label"> No
													</label>
												</div>
											</div>
											<div class="col-md-2">
												<div class="form-check mb-0">
													<input type="radio" value="2" id="isBelongingTaken_yes"
														class="form-check-input" formControlName="isBelongingTaken">
													<label for="isBelongingTaken_yes" class="form-check-label">
														Yes</label>
												</div>
											</div>
											<div class="col-md-2">
												<div class="form-check mb-0">
													<input type="radio" value="3" id="isBelongingTaken_na"
														class="form-check-input" formControlName="isBelongingTaken">
													<label for="isBelongingTaken_na" class="form-check-label">
														N/A</label>
												</div>
											</div>
										</div>
									</div>
									<div class="bg-gray form-theme box-full py-xl-4 py-md-3 py-2">
										<div class="row  g-3 g-xl-4 align-items-center mb-3">
											<div class="col-xl-4">
												<label>
													How did the client leave the service?
												</label>
											</div>
											<div class="col-xl-4 col-md-6">
												<div class="form-check mb-0">
													<input type="radio" value="1" id="isLeave_1"
														class="form-check-input" formControlName="isLeave"
														(change)="changeLeave()">
													<label for="isLeave_1" class="form-check-label">
														Transported to a safe place (please advise the location)
													</label>
												</div>
											</div>
											<div class="col-xl-4 col-md-6">
												<input #addresstext type="text" class="form-control"
													formControlName="leaveComment1" id="leaveComment1">
											</div>
										</div>
										<div class="">
											<div class="form-check mb-2">
												<input type="radio" value="2" id="isLeave_2" class="form-check-input"
													formControlName="isLeave" (change)="changeLeave()">
												<label for="isLeave_2" class="form-check-label">
													Transported via Ambulance
												</label>
											</div>
											<div class="form-check mb-2">
												<input type="radio" value="3" id="isLeave_3" class="form-check-input"
													formControlName="isLeave" (change)="changeLeave()">
												<label for="isLeave_3" class="form-check-label">
													Picked up by QPS
												</label>
											</div>
											<div class="form-check mb-3">
												<input type="radio" value="4" id="isLeave_4" class="form-check-input"
													formControlName="isLeave" (change)="changeLeave()">
												<label for="isLeave_4" class="form-check-label"> Other (please provide
													details)</label>
											</div>
											<div class="mb-3">
												<textarea class="form-control" rows="2"
													formControlName="leaveComment2"></textarea>
											</div>
										</div>
									</div>
								</section>
								<div class="border_top  pt-xl-4 pt-md-3 pt-2">
									<div class="row justify-content-end g-2 g-md-3 g-xxl-4">
										<div class="col-auto" *ngIf="isCheckout && !isBulkCheckin">
											<button type="submit" class="btn btn-red"
												[disabled]="diversionForm.invalid || !isReferalValid()">Save</button>
										</div>
										<div class="col-auto" *ngIf="!isCheckout">
											<button [disabled]="diversionForm.invalid" type="submit"
												class="btn btn-red"> Check In
											</button>
										</div>
										<div class="col-auto" *ngIf="isCheckout">
											<button [disabled]="diversionForm.invalid || !isReferalValid()"
												type="button" (click)="diversionCheckout()" class="btn btn-red">
												Checkout </button>
										</div>
									</div>
								</div>

							</section>
						</div>
					</div>
				</div>
			</div>
		</div>
	</form>
</div>

<ng-template #ReferralModal let-modal>
	<div class="modal-header">
		<h3 class="modal-title">Referral Form</h3>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body" [formGroup]="diversionForm">
		<div class="form-theme">
			<p>Please make sure to consider that consent can only be obtained if (All options must be ticked):</p>
			<div class="form-check mb-3">
				<input type="checkbox" value="true" id="thingsToConsider" class="form-check-input"
					formControlName="thingsToConsider"
					[ngClass]="{'is-invalid':diversionForm.controls['thingsToConsider'].errors}"
					[required]="thingsToConsiderRequired('thingsToConsider')"
					[checked]="diversionForm.controls['thingsToConsider'].value === 'true'">
				<label for="thingsToConsider" class="form-check-label">
					The Client is sober and not intoxicated
				</label>
				<div class="invalid-feedback" *ngIf="thingsToConsiderRequired('thingsToConsider')">
					thingsToConsider is required
				</div>
			</div>
			<div class="form-check mb-3">
				<input type="checkbox" value="true" id="thingsToConsider2" class="form-check-input"
					formControlName="thingsToConsider2"
					[ngClass]="{'is-invalid':diversionForm.controls['thingsToConsider2'].errors}"
					[required]="thingsToConsiderRequired('thingsToConsider2')"
					[checked]="diversionForm.controls['thingsToConsider2'].value === 'true'">
				<label for="thingsToConsider2" class="form-check-label">
					The Client is sound mind and their decision-making is not impaired
				</label>
				<div class="invalid-feedback" *ngIf="thingsToConsiderRequired('thingsToConsider2')">
					thingsToConsider2 is required
				</div>
			</div>
			<div class="form-check mb-4">
				<input type="checkbox" value="true" id="thingsToConsider3" class="form-check-input"
					formControlName="thingsToConsider3"
					[ngClass]="{'is-invalid':diversionForm.controls['thingsToConsider3'].errors}"
					[required]="thingsToConsiderRequired('thingsToConsider3')"
					[checked]="diversionForm.controls['thingsToConsider3'].value === 'true'">
				<label for="thingsToConsider3" class="form-check-label">
					The Client has provided verbal consent
				</label>
				<div class="invalid-feedback" *ngIf="thingsToConsiderRequired('thingsToConsider3')">
					thingsToConsider3 is required
				</div>
			</div>
			<div class="">
				<div class="mb-3">
					<label>The Client Agrees That:</label>
				</div>
				<div class="form-check mb-3">
					<input type="checkbox" value="true" id="agreeFor" class="form-check-input"
						formControlName="agreeFor">
					<label for="agreeFor" class="form-check-label">
						My personal details may be used so that the following additional support can be provided to me:
					</label>
				</div>
				<div class="row align-items-center mb-3">
					<div class="col-lg-5">
						<div class="form-check mb-2">
							<input type="checkbox" value="" id="isReferralSupport" class="form-check-input"
								formControlName="isReferralSupport">
							<label for="isReferralSupport" class="form-check-label">
								A referral to a support service
							</label>
						</div>
					</div>
					<div class="col-lg-6">
						<select formControlName="referrelId" id="referrelId" class="form-select">
							<option value="" selected disabled> Select Contact </option>
							<option *ngFor="let loc of contactList" value="{{loc.id}}">{{loc.firstName}}
								{{loc.lastName}}
								({{loc.organizationName}} )</option>
						</select>
					</div>
				</div>

				<textarea class="form-control" rows="2" formControlName="agreeForDetail3"></textarea>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-red"
			[disabled]="thingsToConsiderRequired('thingsToConsider') || thingsToConsiderRequired('thingsToConsider2') || thingsToConsiderRequired('thingsToConsider3')"
			(click)="modal.close('Close click')">Save</button>
		<button type="button" class="btn btn-red" (click)="modal.close('Close click')">Close</button>
	</div>
</ng-template>